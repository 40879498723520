<template>
  <div class="pt-15">
    <div class="order-overview">
      <div class="order-title active no-collapse">
        <span><font-awesome-icon icon="eye"/></span>
        {{ $t("order_overview") }}
      </div>
      <div class="order-overview-detail hide">
        <div>
          <div class="first tab-title">
            <div header-tag="header" class="head" role="tab">
              <div block class="head-wrap">
                <span v-if="cartCount != 1">{{
                  $t("items_in_cart", { numberOf: cartCount })
                }}</span>
                <span v-else>{{ $t("item_in_cart") }}</span>
              </div>
            </div>
            <div id="accordion-1">
              <div class="collapse-wrap">
                <div class="single-order-view-wrap fancy-scroll">
                  <div
                    class="single-order-view"
                    v-for="cartItem of cartItems"
                    :key="cartItem.id"
                  >
                    <!-- <span class="trash-icon">
                      <b-link class="pr-1">
                        <font-awesome-icon
                          icon="trash-alt"
                          id="show-btn"
                          @click="
                            $bvModal.show('product-delete');
                            removeItem(cartItem.id);
                          "
                        />
                      </b-link>
                    </span> -->
                    <div class="order-img">
                      <b-img
                        :src="cartItem.product.image.url"
                        :alt="cartItem.product.name"
                      />
                    </div>

                    <div class="order-des">
                      <div class="single-row">
                        <label
                          >{{ cartItem.product.name }}<br /><span
                            class="qty-sum"
                          >
                            {{ $t("quantity") }}: {{ cartItem.quantity }}
                          </span></label
                        >

                        <div class="price-wrap">
                          <span class="rate">{{
                            formatCurrency(cartItem.prices.price_incl_tax.value)
                          }}</span>
                          <span v-if="cartItem.product.unit">
                            <br />{{ cartItem.product.unit }}
                          </span>
                        </div>
                      </div>

                      <div v-if="cartItem.configurable_options">
                        <div>
                          <span
                            v-for="(option,
                            index) of cartItem.configurable_options"
                            :key="index"
                            class="product-options d-block"
                          >
                            <span class="product-options-title"
                              >{{ option.option_label }}:&nbsp;</span
                            >
                            <span class="product-options-value">{{
                              option.value_label
                            }}</span>
                          </span>
                        </div>
                      </div>
                      <div v-if="cartItem.bundle_options">
                        <div>
                          <span
                            v-for="(option, index) of cartItem.bundle_options"
                            :key="index"
                            class="product-options d-block"
                          >
                            <span class="product-options-title"
                              >{{ option.label }}:&nbsp;</span
                            >
                            <span class="product-options-value">{{
                              option.values[0].quantity
                            }}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="extra-cost-wrap">
                  <div class="subtotal">
                    <div class="t-title">
                      <label>{{ $t("subtotal") }}</label>
                    </div>
                    <span>{{ formatCurrency(cartSubTotalPrice) }}</span>
                  </div>
                  <div class="shipping-rate" v-if="shippingMethod != null">
                    <label>{{ $t("shipping_cost") }} </label>
                    <span>{{
                      formatCurrency(shippingMethod.amount_including_tax.value)
                    }}</span>
                  </div>
                  <div class="shipping-rate" v-else>
                    <label>{{ $t("shipping_cost") }} </label>
                    <span>{{ formatCurrency(0) }}</span>
                  </div>
                </div>

                <div class="total">
                  <div class="t-title">
                    <label>{{ $t("total") }}</label>
                    <span
                      v-if="cartTotalTaxPrices && cartTotalTaxPrices.length > 0"
                    >
                      {{
                        $t("including_21_vat", {
                          amount: formatCurrency(
                            cartTotalTaxPrices[0].amount.value
                          ),
                        })
                      }}
                    </span>
                  </div>

                  <span class="rate bold">{{
                    formatCurrency(cartTotalPrice)
                  }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="second tab-title">
            <div header-tag="header" class="head-coupon" role="tab">
              <div block v-b-toggle.accordion-3 class="head-wrap">
                <span>{{ $t("coupon_discount") }}</span>
                <span class="accordian-icon"
                  ><font-awesome-icon icon="chevron-down"
                /></span>
              </div>
            </div>
            <b-collapse id="accordion-3" role="tabpanel">
              <div class="collapse-wrap">
                <b-form class="coupon-form-inner">
                  <div class="coupon-form">
                    <b-form-input
                      id="inline-form-input-name"
                      placeholder=""
                      v-model="couponCode"
                    ></b-form-input>
                  </div>

                  <b-button @click="addCoupon" class="btn-info">{{
                    $t("add")
                  }}</b-button>
                </b-form>
              </div>
            </b-collapse>
          </div>
          <div class="third tab-title">
            <div header-tag="header" class="head-coupon" role="tab">
              <div block v-b-toggle.accordion-4 class="head-wrap">
                <span>{{ $t("comment_with_order") }}</span>
                <span class="accordian-icon"
                  ><font-awesome-icon icon="chevron-down"
                /></span>
              </div>
            </div>
            <b-collapse id="accordion-4" role="tabpanel">
              <div class="collapse-wrap">
                <b-form-textarea
                  class="pb-10"
                  id="textarea-large"
                  size="md"
                  rows="4"
                  :placeholder="$t('add_comment_to_order')"
                  v-model="customerNote"
                  @change="addCustomerNote"
                ></b-form-textarea>
              </div>
            </b-collapse>
          </div>
          <div class="order-check-box">
            <div>
              <b-form-group id="input-group-12" v-if="!hideNewsLetter">
                <b-form-checkbox
                  id="newsletter"
                  v-model="SubscribeNewsletter"
                  name="news-letter-subscribe"
                  value="yes"
                  unchecked-value="no"
                  ><span class="">{{
                    $t("subscription_newsletter")
                  }}</span></b-form-checkbox
                >
              </b-form-group>
            </div>
          </div>
          <div class="pri-note">
            {{ $t("placing_order_1") }}
            <b-link class="span-txt" @click="openModel('terms')">{{
              $t("term_condition")
            }}</b-link>
            {{ $t("and_our") }}
            <b-link class="span-txt" @click="openModel('privacy')">{{
              $t("privacy_statment")
            }}</b-link
            >.
          </div>
          <b-modal
            id="term-condition"
            class="product-modal-inner"
            centered
            hide-footer
            content-class="defaul-modal"
            dialog-class="extra-big-modal"
            size="xl"
          >
            <template v-if="openModalName == 'terms'" #modal-title class="bold"
              >{{ $t("term_condition") }}
            </template>
            <template v-else #modal-title class="bold"
              >{{ $t("privacy_policy") }}
            </template>
            <div class="modal-text d-block border-bottom"></div>
            <div
              class="contacts-block"
              v-if="
                typeof termsConditions == 'object' && openModalName == 'terms'
              "
            >
              <cmsBlockSimple
                v-if="typeof termsConditions == 'object'"
                :identifier="termsConditions.identifier"
              />
            </div>
            <div
              class="contacts-block"
              v-if="
                typeof privacyPolicy == 'object' && openModalName == 'privacy'
              "
            >
              <cmsBlockSimple
                v-if="typeof privacyPolicy == 'object'"
                :identifier="privacyPolicy.identifier"
              />
            </div>

            <div class="modal-btn-box pt-20"></div>
          </b-modal>

          <div class="submit-btn" :class="!isSubmitDisappear ? 'd-none' : ''">
            <b-button
              @click="submitForm"
              :disabled="buttonClicked"
              class="btn btn-success"
            >
              {{ $t("pay") }}
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Cart from "@storefront/core/modules/cart/mixins";
import config from "@config";
import shoppingImg from "@/esf_utrecht_bankxl/assets/images/s-prd-img.jpg";
import cmsBlockSimple from "@/esf_utrecht_bankxl/core/components/core/BlockSimple";

export default {
  name: "OrderOverviewComponent",
  mixins: [Cart],
  data() {
    return {
      shoppingImg,
      couponCode: "",
      customerNote: "",
      SubscribeNewsletter: "no",
      openModalName: "",
      buttonClicked: false,
      hideNewsLetter: false,
    };
  },
  components: {
    cmsBlockSimple,
  },

  computed: {
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
    isSubmitDisappear() {
      return (
        this.$store.getters["cart/getShippingMethod"] &&
        this.$store.getters["cart/getPaymentMethod"]
      );
    },
    shippingMethod() {
      return this.$store.getters["cart/getShippingMethod"];
    },
    termsConditions() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "terms_and_conditions"
      );
    },
    privacyPolicy() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "privacy_policy"
      );
    },
  },

  methods: {
    formatCurrency(amount) {
      return this.$helpers.formatCurrency(amount);
    },
    openModel(name) {
      this.openModalName = name;
      this.$bvModal.show("term-condition");
    },
    removeProduct(data) {
      this.productID = data;
    },
    async submitForm() {
      this.checkSiblingFields();
      if (this.checkFields()) {
        this.buttonClicked = true;
        const getShipping = this.$store.getters["cart/getShippingMethod"];
        const getPayment = this.$store.getters["cart/getPaymentMethod"];
        if (getShipping == null) {
          this.$emit("invalidateShipping");
        } else if (getPayment == null) {
          this.$emit("invalidatePayment");
        } else {
          const retval = await this.$store.dispatch("cart/addPaymentMethod");
          localStorage.removeItem("orderHash");

          if (retval == false) {
            this.buttonClicked = false;
          }
          if (retval.order.hash) {
            localStorage.setItem("orderHash", retval.order.hash);
          }
          if (retval != false) {
            const url = retval.redirect_url.replace(config.app_url, "/");
            if (this.isLoggedIn == true) {
              this.$store.dispatch("user/loadOrders", { page: 1, perPage: 10 });
            }
            if (url.includes("https://")) {
              window.location = url;
            } else {
              this.$store.dispatch("cart/loadCart");
              this.$router.push(
                url + "?transactionid=" + retval.order.increment_id
              );
            }
            if (url.includes("/checkout/onepage/success/")) {
              this.$store.dispatch("cart/unLoad");
            }
            this.$store.commit("cart/setComment", "");
          }
        }
        this.buttonClicked = false;
      }
      this.$store.commit("setShippingMethod", null);
      this.$store.commit("cart/setCartNewAddress", []);
    },
    async addCoupon() {
      if (this.couponCode != "") {
        const retval = await this.$store.dispatch("cart/addCouponCode", {
          code: this.couponCode,
        });
        if (retval == true) {
          const msg = {
            type: "success",
            title: this.$t("coupon_code"),
            text: this.$t("coupon_code_ok", { code: this.couponCode }),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
          this.couponCode = "";
        }
      }
    },
    async addCustomerNote() {
      this.$store.commit("cart/setComment", this.customerNote);
    },
    toggleCollapse(event) {
      if (window.innerWidth <= 991) {
        if (!event.target.classList.contains("active")) {
          event.target.classList.add("active");
        } else {
          event.target.classList.remove("active");
        }
      }
    },
    checkFields() {
      var errorFields = [];
      var storeValidation = this.$store.getters["cart/getStoreValidation"];
      for (const property in storeValidation) {
        storeValidation[property].forEach((error) => {
          errorFields.push(error);
        });
      }

      const modifiedErrorFields = errorFields.map((field) =>
        field.trim().toLowerCase() === "shipping_streetdisplay"
          ? "Postcode en huisnummercombinatie (verzendadres)"
          : field
      );

      if (modifiedErrorFields.length > 0) {
        const msg = {
          type: "danger",
          title: this.$t("Checkout fields incomplete"),
          text: this.$t("The following fields are invalid:", {
            errors: modifiedErrorFields.join(", "),
          }),
        };
        this.$store.dispatch("messages/sendMessage", { message: msg });
        return false;
      }
      return true;
    },
    checkSiblingFields() {
      this.$root.$emit("checkFields");
    },
  },
  mounted() {
    this.$root.$on("subscribe_newsletter", (value) => {
      if (this.SubscribeNewsletter !== value) {
        this.SubscribeNewsletter = value;
      }
    });
    if (this.isLoggedIn) {
      var user = this.$store.getters["user/getCurrentUser"];
      this.hideNewsLetter = user.is_subscribed;
    }
  },
  watch: {
    SubscribeNewsletter(value) {
      this.$root.$emit("subscribe_newsletter", value);
    },
  },
};
</script>

<style>
.order-overview {
  border: 1px solid #ced4da;
  padding: 25px;
}
</style>
