<template>
  <div class="tab-two tab-content-detail">
    <div class="heading mb-head-5">
      {{ $t("account_preferences_tab_title") }}
    </div>
    <div class="sub-heading sub-pb-25">
      {{ $t("account_preferences_heading") }}
    </div>
    <div class="smilexl-sm-txt">
      <div class="left-h">
        <div class="heading pb-head-5">{{ $t("your_information") }}</div>
        <div class="product-acc-content">
          <p>{{ user.firstname }} {{ user.lastname }}</p>
          <p v-if="user.addresses">{{ user.addresses[0].street.join(" ") }}</p>
          <p v-if="user.addresses">{{ user.addresses[0].postcode }}</p>
          <p v-if="user.addresses">{{ user.addresses[0].city }}</p>
          <p class="pb-20 sm-pb-0">{{ user.email }}</p>
          <p class="pb-20 sm-pb-0 sm-my-15">
            {{ $t("change_user_information") }}
          </p>
          <div class="download-link text-left d-pt-15 pb-10">
            <b-link class="text-decoration" @click="EditUser()">
              {{ $t("personal_information") }}
            </b-link>
          </div>
          <div class="download-link pb-10">
            <b-link class="text-decoration" @click="EditAdresses()">
              {{ $t("address_information") }}
            </b-link>
          </div>
        </div>
      </div>
      <div class="left-r">
        <b-img :src="logo" alt="" class="pt-space-20 pl-space-20" />
      </div>
    </div>

    <!--smile-txt-end -->
    <div class="smilexl-sm-txt">
      <div class="left-h">
        <div class="heading pb-head-5">{{ $t("account_security") }}</div>
        <div class="product-acc-content">
          <p class="pb-20">{{ $t("account_security_content") }}</p>
          <div class="download-link pb-10">
            <b-link class="text-decoration" @click="EditPassword()">
              {{ $t("change_password") }}
            </b-link>
          </div>
          <div class="download-link pb-10">
            <b-link class="text-decoration" @click="EditPrivacyPreferences()">
              {{ $t("privacy_preferences") }}</b-link
            >
          </div>
        </div>
      </div>
      <div class="left-r">
        <b-img :src="logo" alt="" class="pt-space-20 pl-space-20" />
      </div>
    </div>
  </div>
</template>
<script>
import config from "@config";
import smilexlLg from "@/esf_utrecht_bankxl/assets/images/smilexl-lg.png";
import mailXl from "@/esf_utrecht_bankxl/assets/images/mail-xl.png";
import safeXl from "@/esf_utrecht_bankxl/assets/images/safe-xl.png";
// import { Logger } from "@storefront/core/lib/logger";
export default {
  name: "AccountTabPreferences",
  props: {
    msg: String,
  },
  computed: {
    user() {
      return this.$store.getters["user/getCurrentUser"];
    },
    tabIndex: {
      get() {
        return this.$store.getters["user/getAccountTabIndex"];
      },
      set(val) {
        this.$store.commit("user/setAccountTabIndex", val);
      },
    },
  },
  methods: {
    EditUser() {
      this.$store.commit("user/setEditUser", true);
    },
    EditPassword() {
      this.$store.commit("user/setEditPassword", true);
    },
    EditEmail() {
      this.tabIndex = 5;
      // this.$store.commit("user/setEditEmail", true);
    },
    EditAdresses() {
      this.$store.commit("user/setEditAddresses", true);
    },
    EditPrivacyPreferences() {
      this.$emit("showPrivacyPreferences", true);
    },
  },
  data() {
    return {
      logo: "",
      safeXl,
      mailXl,
      smilexlLg,
    };
  },
  mounted() {
    this.logo = config.logo.image;
  },
};
</script>
